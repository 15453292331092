.navbar ul li a {
  color: white !important;
}

.career-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 40px; */
    background-color: #ffffff00;
    border-radius: 10px;
    /* gap: 10px; */
    /* max-width: 600px; */
    margin: auto;
    /* margin-top: -180px; */
    position: relative;
  }
  .career-section .career-section-1 {
    width: 100%;
    text-align: center;
    padding: 150px 100px 100px 100px;
  }
  
  
  .career-title {
    font-size: 30px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  
  .career-subtitle {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-weight: 400;
  }
  
  .career-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 10px; */
    width: 401px;
    border: 1px solid #ccc;
    padding: 3px;
    border-radius: 5px;
    margin: 20px auto;
  }

  .career-search-icon{
    position: absolute;
    margin-left: 10px;
    width: 10px;
    height: 10px;
    color: white;
    background: white;
    margin-top: 3px;
  }
  
  .search-input {
    flex-grow: 1;
    padding: 10px 10px 10px 30px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
  }
  .search-input:focus-visible {
    outline: none;
  }
  
  .search-input::placeholder {
    color: #95a5a6;
    /* font-style: italic; */
    font-size: 10px;
  }
  
  .find-jobs-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: rgba(41, 128, 185, 1);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .find-jobs-button:hover {
    background-color: rgba(41, 128, 185, 0.8);
  }
  
  .career-line-image{
    /* margin-top: -26px; */
  }

  .career-content-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
  }

  .career-title-second-heading{
    color: rgba(41, 128, 185, 1);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 5px;
  }

  .career-heading-sub{
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  .main-list-opportunities{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 20px;
  }

  .sub-list-opportunities-upper-conatiner{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    .sub-list-opportunities{
      padding: 15px;
      border: 1px solid rgba(44, 62, 80, 0.15);
      background-color: rgba(236, 240, 241, 0.3);
      line-height: 20px;
      width: 33%;
      .position-opportunities{
        font-size: 16px;
        font-weight: 700;
        color: rgba(44, 62, 80, 1);
      }
      .job-type-postedtime-main{
        display: flex;
        margin-top: 5px;
        .job-type-opportunities{
          display: flex;
          text-align: center;
          justify-content: center;
          .icon-job-type-opportunities{
            /* display: flex; */
            .suitcase{
              width: 12px;
              height: 12px;
              font-weight: 600;
              color: rgba(160, 171, 184, 1);
            }
              }
              .text-job-type{
                margin-left: 5px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(160, 171, 184, 1);
              }
        }
        .icon-postedtime-opportunities{
          display: flex;
          margin-left: 15px;
              text-align: center;
              justify-content: center;
              .icon-postedtime{
                font-weight: 600;
                color: rgba(160, 171, 184, 1);
                .ClockPost{
                  width: 12px;
                  height: 12px;
                  font-weight: 600;
                  color: rgba(160, 171, 184, 1);
                }
              }
              .postedtime{
                margin-left: 5px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(160, 171, 184, 1);
              }
            }
          }
          .description-opportunities{
            font-size: 14px;
            font-weight: 500;
            margin-top: 10px;
            color: rgba(102, 102, 102, 1);
          }
          .button-arrow-opportunities{
            margin-top: 20px;
            display: flex;
            justify-content: end;
            .read-more-opportunities{
              background-color: rgba(41, 128, 185, 1);
                border: none;
                color: rgba(255, 255, 255, 1);
                padding: 5px 0px 5px 5px;
                /* position: absolute; */
                display: flex;
                align-items: center;
                cursor: pointer;
                .caret-icon-read-more{
                  margin: auto 5px;
                }
            }
          
            .main-arrow-opportunities{
              position: relative;
              display: flex;
              height: 25px;
              .arrow-opportunities-left{     
                margin-left: 70px;
                }
               .arrow-opportunities-right{
                    /* width: 24px; */
                    /* margin-right: 60px; */
                }
            }
          }
          
    }
  }
  .sub-list-opportunities-lower-conatiner{
    display: flex;
    gap: 20px;
    .sub-list-opportunities{
      padding: 15px;
      border: 1px solid rgba(44, 62, 80, 0.15);
      background-color: rgba(236, 240, 241, 0.3);
      line-height: 20px;
      width: 33%;
      .position-opportunities{
        font-size: 16px;
        font-weight: 700;
        color: rgba(44, 62, 80, 1);
      }
      .job-type-postedtime-main{
        display: flex;
        margin-top: 5px;
        .job-type-opportunities{
          display: flex;
          text-align: center;
          justify-content: center;
          .icon-job-type-opportunities{
            /* display: flex; */
            .suitcase{
              width: 12px;
              height: 12px;
              font-weight: 600;
              color: rgba(160, 171, 184, 1);
            }
              }
              .text-job-type{
                margin-left: 5px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(160, 171, 184, 1);
              }
        }
        .icon-postedtime-opportunities{
          display: flex;
          margin-left: 15px;
              text-align: center;
              justify-content: center;
              .icon-postedtime{
                font-weight: 600;
                color: rgba(160, 171, 184, 1);
                .ClockPost{
                  width: 12px;
                  height: 12px;
                  font-weight: 600;
                  color: rgba(160, 171, 184, 1);
                }
              }
              .postedtime{
                margin-left: 5px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(160, 171, 184, 1);
              }
            }
          }
          .description-opportunities{
            font-size: 14px;
            font-weight: 500;
            margin-top: 10px;
            color: rgba(102, 102, 102, 1);
          }
          .button-arrow-opportunities{
            margin-top: 20px;
            display: flex;
            justify-content: end;
            .read-more-opportunities{
              background-color: rgba(41, 128, 185, 1);
              border: none;
              color: rgba(255, 255, 255, 1);
              padding: 5px 0px 5px 5px;
              /* position: absolute; */
              display: flex;
              align-items: center;
              cursor: pointer;
              .caret-icon-read-more{
                margin: auto 5px;
              }
            }
          
            .main-arrow-opportunities{
              position: relative;
              display: flex;
              height: 25px;
              .arrow-opportunities-left{     
                margin-left: 70px;
                }
               .arrow-opportunities-right{
                    /* width: 24px; */
                    /* margin-right: 60px; */
                }
            }
          }
          
    }
  }



