.aboutus-main {
  font-family: sans-serif;
  color: rgba(255, 255, 255, 1);
  position: relative;
  /* color: black; */
  margin-top: -115px;
  margin-bottom: 69px;
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 700;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 50px;
  background: grey;
}
.careers-container-aboutus{
        /* width: 1600px; */
        height: auto;
        gap: 0px;
        background-color: 
        rgba(255, 255, 255, 1);
        display: flex;
        flex-direction: column;
}

.upper-section-aboutus{
    position: relative;
    margin-top: 125px;
}

.testimonials-title-aboutus{
    font-family: Merriweather, serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.04em;
    margin-left: 130px;
    color: rgba(41, 128, 185, 1);
}

.testimonials-heading-aboutus{
    font-family: Lato, sans-serif;
    font-size: 30px;
    width: 33%;
    letter-spacing: 2px;
    font-weight: 700;
    color: rgba(44, 62, 80, 1);
    margin: 5px;
    margin-left: 125px;
    margin-top: 10px;
}

.left-container-aboutus{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    padding: 20px;
    width: 32%;
    margin-left: 110px;
    margin-top: 30px;
    max-width: 502px;
}

.heading-aboutus{
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    color: rgba(102, 102, 102, 1);
}

.container-aboutus {
    display: flex;
    flex-direction: column;
  }
  
  .item-aboutus {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
  }
  
  .tick-image-aboutus {
    position: absolute;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  
  .text-content-aboutus {
    display: flex;
    position: absolute;
    margin-left: 28px;
    margin-top: 20px;
    max-width: 53vw;
  }
  
  .subheading-aboutus {
    font-weight: bold;
    font-size: 16px;
  }
  
  .content-aboutus {
    font-size: 16px;
    width: 70%;
    color: rgba(102, 102, 102, 1);
    margin-left: 5px;
  }
  
  .right-container-aboutus {
    display: flex;
    position: absolute;
    height: 500px;
    margin-left: 65%;
    margin-top: 100px;
  }
  
  .left-side-image-aboutus,
  .right-side-image-aboutus {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10px;
  }
  
  .image-one-aboutus {
    height: 180px; 
    width: 150px;
    margin-top: 45px;
  }
  .image-two-aboutus {
    height: 230px;
    width: 150px;
    margin-bottom: 20px;
  }

  .image-three-aboutus {
    height: 230px;
    width: 150px;
    margin-top: -5px;
  }

  .image-four-aboutus {
    height: 180px; 
    width: 150px;
    margin-bottom: 70px;
  }
  
  .second-container-aboutus{
    background-color: rgba(44, 62, 80, 1);
    display: flex;
    margin-top: 25px;
    height: 280px;
    /* width: 80%; */
  }
  .sub-second-container-aboutus{
    background-color: rgba(44, 62, 80, 1);
    display: flex;
    width: 80%;
    margin: auto;
  }

 .inner-divone{
    /* width: 12%; */
    max-width: 123px;
    max-height: 117px;
    height: 150%;
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: column;
    /* border: 1px solid white; */
 }

 .inner-divtwo{
    max-height: 117px;
    height: 150%;
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: column;
    /* border: 1px solid white; */
 }
 .inner-divthree{
    max-height: 117px;
    height: 150%;
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: column;
    /* border: 1px solid white; */
 }
 .inner-divfour{
    max-height: 117px;
    height: 150%;
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: column;
    /* border: 1px solid white; */
 }

 .count{
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
 }

 .text-aboutus{
    font-size: 14px;
color: rgb(184, 184, 184)
 }

 .aboutus-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
 }

 .line-image-aboutus{
    display: flex;
    justify-content: center;
    transform: rotate(90deg);
    height: 10px;
    margin-top: 33px;
 }

 .aboutus-content-div{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
 }

 .aboutus-title{
    font-family: Merriweather, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.04em;
    text-align: center;
    color: rgba(41, 128, 185, 1);
 }

 .aboutus-heading-sub{
    font-family: Lato, sans-serif;
    font-size: 21px;
    width: 80%;
    font-weight: 700;
    text-align: center;
    color: rgba(44, 62, 80, 1);
    margin: 10px auto;
    padding-bottom: 50px;
 }

 .aboutus-success-container-core {
    display: flex;
    background: rgba(255, 255, 255, 1);
    margin: 50px auto;
  }
  
  .aboutus-success-item-one {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    background:rgba(236, 240, 241, 0.5);
    padding: 10px 20px;
    /* border-radius: 5px; */
  }
  .aboutus-success-item-two {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    background:rgba(236, 240, 241, 1);
    padding: 10px 20px;
    /* border-radius: 5px; */
  }
  
  .aboutus-success-count {
    font-size: 14px;
    color: white;
    font-weight: bold;
  }
  
  .aboutus-success-subheading {
    font-size: 16px;
    color: rgba(41, 128, 185, 1);
  }
  
  .aboutus-success-text{
font-size: 14px;
color: rgba(102, 102, 102, 1);
  }

  .aboutus-our-teams-main-section{
    display: flex;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .aboutus-our-teams-left-section{
    width: 40%;
  }

  .aboutus-our-teams-left-section-div{
    margin-left: 27%;
    width: 81%;
  }
  .aboutus-our-teams-title{
    color: rgba(41, 128, 185, 1);
    font-size: 16px;
    font-weight: 400;
  }

  .aboutus-our-teams-heading{
    color: rgba(44, 62, 80, 1);
    margin-top: 10px;
    font-size: 28px;
    font-weight: 700;
  }

  .aboutus-our-teams-text{
    margin-top: 20px;
    color: rgba(102, 102, 102, 1);
    font-weight: 400;
    font-size: 14px;
  }


  .aboutus-our-teams-right-section {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .aboutus-our-teams-main-picture {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .aboutus-our-teams-picture-one,
  .aboutus-our-teams-picture-five {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 190px;
    height: 260px;
    background-color: rgba(44, 62, 80, 1);
    padding-top: 20px;
  }

.aboutus-our-teams-picture-center{
    margin-top: 150px;
}

  .aboutus-our-teams-picture-two,
  .aboutus-our-teams-picture-five{
    margin-top: 10px;
  }
  .aboutus-our-teams-picture-two,
  .aboutus-our-teams-picture-three,
  .aboutus-our-teams-picture-four{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 190px;
    height: 260px;
    background-color: rgba(236, 240, 241, 1);
    border: 1px solid rgba(44, 62, 80, 1);
    padding-top: 20px;
  }
  
  .our-team-pics img {
    width: 150px;
    height: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .aboutus-our-teams-name-one,
  .aboutus-our-teams-name-five {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
  }

  .aboutus-our-teams-name-two,
  .aboutus-our-teams-name-three,
  .aboutus-our-teams-name-four{
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(44, 62, 80, 1);
  }
 
  
  .aboutus-our-teams-designation-one,
  .aboutus-our-teams-designation-five {
    font-size: 12px;
    color: 
    rgba(255, 255, 255, 0.8);
    margin-top: 3px;
  }
  .aboutus-our-teams-designation-two,
  .aboutus-our-teams-designation-three,
  .aboutus-our-teams-designation-four{
    font-size: 12px;
    margin-top: 3px;
    color: rgba(41, 128, 185, 1);
  }
 
  