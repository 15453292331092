.services-main {
  font-family: sans-serif;
  color: rgba(255, 255, 255, 1);
  position: relative;
  /* color: black; */
  margin-top: -115px;
  margin-bottom: 69px;
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 700;
}
.service-container {
  /* text-align: center; */
  margin-bottom: 40px;
}

.service-line-image {
  text-align: center;
  margin-top: -45px;
}

.service-content-div {
  text-align: center;
}

.service-title-second-heading {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 400;
  text-align: center;
  color: rgba(41, 128, 185, 1);
}

.service-heading-sub {
  font-size: 24px;
  width: 37vw;
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 700;
  color: rgba(44, 62, 80, 1);
}

.service-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.service-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 40px;
}

.service-right {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service-center {
  flex: 1;
  text-align: center;
}

.service-item {
  padding: 20px;
  border: 1px solid rgba(41, 128, 185, 0.2);
  background-color: #f9f9f9;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-align: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 1);
  height: 180px;
  width: 190px;
}

.service-item-upper {
  display: flex;
}
.service-item-image {
}
.service-item-image img {
  width: 30px;
  height: 30px;
}
.service-item-text {
  font-size: 12px;
  margin-left: 5px;
  margin: auto 0px 10px 10px;
  text-align: center;
  display: flex;
  justify-content: left;
  width: 100%;
  /* max-width: 90%; */
  color: rgba(51, 51, 51, 1);
  font-weight: 600;
}

.service-item-lower {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  text-align: left;
}

.service-center-page {
  border: 1px solid rgba(41, 128, 185, 0.2);
  /* flex: 1 1; */
  width: 150px;
  height: 150px;
  text-align: center;
}
.service-center-page img {
  max-width: 130px;
  height: 130px;
  margin-top: 10px;
}

.upper-section-service {
  position: relative;
  margin-top: 125px;
}

.line-image {
  width: 124px;
  height: 16px;
  position: absolute;
}

.testimonials-title-service {
  font-family: Merriweather, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.04em;
  margin-left: 130px;
  color: rgba(41, 128, 185, 1);
}

.testimonials-heading-service {
  font-family: Lato, sans-serif;
  font-size: 24px;
  width: 34%;
  letter-spacing: 2px;
  font-weight: 700;
  color: rgba(44, 62, 80, 1);
  margin: 5px;
  margin-left: 125px;
  margin-top: 10px;
}

.first-last-section-div {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    gap: 50px;
}
.second-last-section-div {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    gap: 50px;
}
.third-last-section-div {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    gap: 50px;
}
.left-last-section-service {
    width: 40vw;
}
.number-left-last-section-service {
    font-weight: 800;
    color: rgb(236 240 241 / 70%);
    font-size: 116px;
}
.text-left-last-section-service {
    margin-top: -35px;
}
.heading-text-left-last-section-service {
    color: rgba(51, 51, 51, 1);
    font-weight: 700;
    font-size: 16px;
    border-bottom: 2px solid rgba(41, 128, 185, 1);
    width: 50%;
    padding-bottom: 10px;
}
.sub-heading-text-left-last-section-service {
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
    width: 80%;
}
.right-last-section-service {
  max-width: 50vw;
}
.right-last-section-service img {
  width: 40vw;
}


