 /* src/styles/App.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .navbar {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    /* background-color: rgb(212 212 212 / 40%); */
    /* opacity: 0.5;  */
    padding: 1em;
    /* position: relative; */
    border-bottom: 1px solid rgba(41, 128, 185, 0.2);
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
  }
  .navbar .navbar-inner {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .navbar .navbar-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    filter: blur(1px);
  }


    .navbar ul {
        list-style-type: none;
        padding: 10px;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        gap: 30px;
        font-weight: 700;
        color: rgba(44, 62, 80, 0.8);
        cursor: pointer;
        text-decoration: none;
      }

.navbar-left {
    display: flex;
    align-items: center;
}

.navbar-logo {
    height: 40px;
    width: 41px;
    margin-right: 10px;
}

.navbar-company-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 10px;
    color: rgba(44, 62, 80, 1);
}
  
  .navbar ul li {
    margin: 0;
  }
  
  .navbar ul li a {
    color: rgba(44, 62, 80, 0.8);
    text-decoration: none;
    cursor: pointer;
  }
  
  .navbar ul li a:hover {
  color: rgba(44, 62, 80, 1)
  }
  
  .home-section {
    position: relative;
    background-image: url('../../src/assets/genoday_home.png');
    background-size: cover;
    background-position: center;
    padding: 308px 0px;
    color: rgb(23, 20, 20); /* Ensure text is visible against the background image */
  }
  
  .container {
    padding: 20px;
  }
  
  .section {
    padding: 50px 0;
  }
  
  h1, h2, h3 {
    color: white; /* Ensure text is visible against the background image */
  }
  