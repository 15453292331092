.resources {
  font-family: sans-serif;
  color: rgba(255, 255, 255, 1);
  position: relative;
  /* color: black; */
  margin-top: -115px;
  margin-bottom: 89px;
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 700;
}
.resources-container-page {
    margin-bottom: 40px;
  }
  
  .resources-line-image {
    text-align: center;
    margin-top: -65px;
  }
  
  .resources-content-div {
    text-align: center;
  }
  
  .resources-title-second-heading {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
    text-align: center;
    color: rgba(41, 128, 185, 1);
  }
  
  .resources-heading-sub {
    font-size: 24px;
    width: 37vw;
    margin: auto;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
    color: rgba(44, 62, 80, 1);
  }

  .main-resources-featured-card{

  }

  .resources-featured-card-up{
    display: flex;
    justify-content: center;
  }
  .resources-featured-card-down{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

.resources-featured-card{
    border: 1px solid rgba(44, 62, 80, 0.15);
    width: 200px;
    margin-left: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.upper-sec-resources-featured-card img {
    width: 200px;
    height: 130px;
}
.lower-sec-resources-featured-card{
    padding: 10px;
}
.first-line-lower-sec-resources-featured-card{
    display: flex;
    text-align: center;
}
.profile-pic-first-line-lower-sec-resources-featured-card img{
    width: 35px;
    height: 35px;
    border-radius: 50px;
}
.userinfo-first-line-lower-sec-resources-featured-card{
    margin-left: 5px;
    margin-top: 10px;
    color: rgba(160, 171, 184, 1);
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.name-first-line-lower-sec-resources-featured-card-scroll{
  font-size: 16px;
}

.heading-first-line-lower-sec-resources-featured-card{
    color: rgba(44, 62, 80, 1);
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
}
.sub-heading-first-line-lower-sec-resources-featured-card{
    color: rgba(102, 102, 102, 1);
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
}
.date-first-line-lower-sec-resources-featured-card{
    color: rgba(160, 171, 184, 1);
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
}
.view-btn-resources{
    background-color: rgba(41, 128, 185, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    padding: 10px 20px;
    text-align: center;
    display: flex;
    margin: auto;
    margin-top: 30px;
}
.last-sec-view-btn-resources{
    background-color: rgba(41, 128, 185, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    padding: 10px 20px;
    text-align: center;
    display: flex;
    margin-left: auto;
    /* margin-top: 30px; */
    margin-right: 35px;
}

.line-image {
    width: 124px;
    height: 16px;
    position: absolute;
  }
  
  .resources-title-service {
    font-family: Merriweather, serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.04em;
    margin-left: 130px;
    color: rgba(41, 128, 185, 1);
  }
  
  .resources-heading-service {
    font-family: Lato, sans-serif;
    font-size: 24px;
    width: 34%;
    letter-spacing: 2px;
    font-weight: 700;
    color: rgba(44, 62, 80, 1);
    margin: 5px;
    margin-left: 125px;
    margin-top: 10px;
  }
  .last-sec-resources{
    margin-top: 50px;
  }


.main-resources-featured-card-scroll{
    display: flex;
    margin-top: 30px;
}
  .resources-featured-card-scroll{
    border: 1px solid rgba(44, 62, 80, 0.15);
    width: 30%;
    height: 60vh;
    margin-left: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.upper-sec-resources-featured-card-scroll img {
    width: 100%;
    height: 200px;
}
.lower-sec-resources-featured-card-scroll{
    padding: 10px;
}
.first-line-lower-sec-resources-featured-card-scroll{
    display: flex;
    text-align: center;
    margin-top: 20px;
}
.profile-pic-first-line-lower-sec-resources-featured-card-scroll img{
    width: 35px;
    height: 35px;
    border-radius: 50px;
}
.userinfo-first-line-lower-sec-resources-featured-card-scroll{
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 4px;
}


.heading-first-line-lower-sec-resources-featured-card-scroll{
    color: rgba(44, 62, 80, 1);
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
}
.sub-heading-first-line-lower-sec-resources-featured-card-scroll{
    color: rgba(102, 102, 102, 1);
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
}
.date-first-line-lower-sec-resources-featured-card-scroll{
    color: rgba(160, 171, 184, 1);
    font-size: 12px;
    font-weight: 500;
}