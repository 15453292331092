.navbar-home {
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  background-color: rgb(212 212 212 / 40%);
  opacity: 0.5; 
  padding: 1em;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(41, 128, 185, 0.2);
}


  .navbar-home ul {
      list-style-type: none;
      padding: 10px;
      margin: 0;
      display: flex;
      justify-content: flex-end;
      gap: 30px;
      font-weight: 700;
      color: rgba(44, 62, 80, 0.8);
      cursor: pointer;
      text-decoration: none;
    }

.navbar-left-home {
  display: flex;
  align-items: center;
}

.navbar-logo-home {
  height: 40px;
  width: 41px;
  margin-right: 10px;
}

.navbar-company-name-home {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  color: rgba(44, 62, 80, 1);
}

.navbar-home ul li {
  margin: 0;
}

.navbar-home ul li a {
  color: rgba(44, 62, 80, 0.8);
  text-decoration: none;
  cursor: pointer;
}

.navbar-home ul li a:hover {
color: rgba(44, 62, 80, 1)
}

.home-container {
    display: flex;
    justify-content: left; /* Aligns the main div to the left */
    align-items: center;
    height: 100vh;
    background-image: url('../assets/genoday_home.png'); /* Adjust the path to your background image */
    background-size: cover;
    background-position: center;
    padding: 20px; /* Adjust the padding as needed */
}

.main-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: rgb(255 255 255 / 0%);
    padding: 20px;
    border-radius: 10px;
    margin-left: 80px;
}

.first-div .icon-text {
    display: flex;
    align-items: center;
    width: 33%;
    gap: 10px;
    background-color: rgba(44, 62, 80, 0.8);
    border-radius: 20px;
    color: white;
    font-size: small;
    padding: 5px;
    font-weight: 600;
}

.first-div .text{
    font-size: 11px;
}

.first-div .icon {
    margin-left: 8px;
    width: 15px;
    height: 15px;
}

.second-div {
    font-size: 35px;
    font-weight: 400;
    width: 85%;
}

.second-div .highlight {
    color: rgba(41, 128, 185, 1);
    font-size: 35px;
    font-weight: 700;
}

.third-div p {
    color: rgba(44, 62, 80, 0.8);
    font-family: Lato, sans-serif;
    font-size: medium;
    width: 84%;
    font-weight: 400;
    line-height: var(--TitleLargeLineHeight);
    letter-spacing: var(--TitleLargeTracking);
    text-align: left;
}

.button-container {
    position: relative;
    display: inline-block;
}

.hover-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(41, 128, 185, 1);
    z-index: 0;
    transform: rotate(-5deg);
    transition: opacity 0.3s ease-in-out;
    border-radius: 5px;
}

.learn-more {
    position: relative;
    z-index: 1;
    padding: 10px 20px;
    background-color: rgba(44, 62, 80, 1);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.learn-more:hover + .hover-bg {
    opacity: 0;
}

.learn-more:hover {
    background-color: rgba(44, 62, 80, 1);
}

.about-us-container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    height: 700px;
  }
  
  .about-us-left {
    /* flex: 1; */
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .image-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 100px;
  }
  
  .third-image {
      position: absolute;
      left: 0px;
      /* right: 220px; */
      bottom: -223px;
      width: 344px;
      height: 182px;
      background-color: rgba(189, 195, 199, 1);
  }
  
  .second-image {
      position: absolute;
      bottom: -190px;
      right: 23%;
      width: 33vw;
      height: 60vh;
  }
  
  .first-image {
      position: absolute;
      bottom: 5px;
      width: 180px;
      height: 200px;
  }
  
  .first-image-box {
      padding: 5px;
      background-color: white;
      width: 151px;
      height: 200px;
      position: relative;
      left: 56%;
      /* top: 8px; */
      bottom: 60px;
  }
  
  .about-us-right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 20px;
  }
  
  .about-us-content {
    display: flex;
    flex-direction: row;
  }
  
  .about-us-center {
    /* border-top: 2px solid rgba(41, 128, 185, 1); */
    margin-top: 76px;
    width: 90px;
  }
  
  .aboutus-line {
      width: 78px;
      position: relative;
      /* display: flex; */
      margin-left: 89%;
      height: 10px;
      bottom: 237px;
  }
  
  .text-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .who-are-we {
      font-size: 14px;
    font-weight: 400;
    color: rgba(41, 128, 185, 1);
    text-transform: uppercase;
  }
  
  .title {
    font-size: 24px;
    color:rgba(44, 62, 80, 1);
    font-weight: 700;
  }
  
  .description {
    font-size: medium;
    color: rgba(102, 102, 102, 1);
    font-family: Merriweather;
  }
  
  .mission-section {
      display: flex;
      flex-direction: column;
      border-bottom: 4px solid rgba(41, 128, 185, 1);
  }
  
  .mission {
    background-color: rgba(44, 62, 80, 0.1);
    padding: 20px;
  }
  
  .mission h3 {
    font-size: 18px;
    font-weight: 500;
    color: rgba(44, 62, 80, 1);
    font-family: Lato;
  }
  
  .mission p{
   color: rgba(102, 102, 102, 1);
    font-size: medium;
    font-weight: 400;
    line-height: 20px;
  }

  /* Services */
  /* src/styles/Services.css */

.services-container {
    background-color: rgba(236, 240, 241, 1);
    padding: 1px;
    padding-bottom: 70px;
  }
  
  .intro-section {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
  }
  
  .intro-text {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .services-line {
    width: 125px;
  }
  
  .intro-title {
    font-size: 14px;
    color: rgba(41, 128, 185, 1);
    font-weight: 400;
    line-height: 17px;
  }
  
  .intro-heading {
    width: 29%;
    font-weight: 700;
    font-size: 28px;
    margin-top: 10px;
    margin-left: 134px;
    color: rgba(44, 62, 80, 1);
    /* text-align: center; */
  }
  
  .services-list {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 40px;
  }
  
  .service-item-home {
    position: relative;
    width: 244px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
  }
  
  .service-icon {
    height: 280px;
  }
  
  .service-icon img {
    width: 244px;
    height: 280px;
  }
  
  .service-title {
    display: flex;
    align-items: center;
    height: 56px;
    font-size: 14px;
    justify-content: center;
    font-weight: 700;
    background-color: rgba(189, 195, 199, 1);
    color: rgba(44, 62, 80, 1);
  }
  
  .service-info {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 62, 80, 0.9);
    color: white;
    bottom: 100%;
    transition: bottom .8s ease-in-out;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(1px);
    color: rgba(255, 255, 255, 0.6);
    display: none;
    bottom: 0;
    transition: height 0.5s;
    height: 0%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .service-info-up {
   
    padding: 0px 0px 1.5px 0px;
    color: white;
    margin-bottom: 5px;
  }
  
  .service-info-down {
    font-size: small;
    font-weight: 400;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
  
  /* Show .service-info on hover */
  .service-item-home:hover .service-info{
    height: 100%;
  }
  .service-item-home .service-info-inner {
    margin: auto;
    flex-direction: column;
    display: flex;
    padding: 10px;
  }
  .service-item-home .service-info-inner .break-line{
    border-bottom: 1.5px solid rgba(255, 255, 255, 0.8);
    margin: auto;
    margin-bottom: 5px;
    width: 100%;
  }
  
  .servicesbutton-container {
    position: relative;
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
  
  .serviceshover-bg {
    position: absolute;
    top: 0px;
    width: 172px;
    height: 100%;
    background-color: rgba(41, 128, 185, 1);
    z-index: 0;
    transform: rotate(-4deg);
    transition: opacity 0.3s ease-in-out;
    border-radius: 5px;
  }
  
  .serviceslearn-more {
    position: relative;
    z-index: 1;
    padding: 10px 20px;
    background-color: rgba(44, 62, 80, 1);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .serviceslearn-more:hover + .hover-bg {
    opacity: 0;
  }
  
  .serviceslearn-more:hover {
    background-color: rgba(44, 62, 80, 1);
  }
  

  /* src/styles/Industries.css */

.industries-container {
    padding: 0px 20px;
  }
  
  .line-image-industries {
    text-align: center;
  }

  .line-image-industries img{
    /* height: 190px; */
    width: 24px;
  }
  
  .industries-title {
    font-family: Merriweather, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.04em;
    text-align: center;
    color: rgba(41, 128, 185, 1);
  }
  
  .industries-heading-sub {
    font-family: Lato, sans-serif;
    font-size: 21px;
    width: 33%;
    font-weight: 700;
    text-align: center;
    color: rgba(44, 62, 80, 1);
    margin: 10px auto;
    padding-bottom: 50px;
  }
  
  .industry-item {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    height: 60vh;
  }
  
  .industry-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
  } 
  
  .industry-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
  
  .industry-left img{
      width: 100%;
      height: 65vh;
      max-height: 100%;
  } 
  
  .industry-right img {
    width: 100%;
    height: 70%;
  }
  
  .industry-number {
    width: 135px;
    height: 116px;
    font-size: 70px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: rgb(189 195 199 / 54%);
    position: absolute;
    font-family: auto;
    margin-bottom: 18%;
    margin-left: 10px;
  }
  
  .industry-content {
    text-align: left;
    background-color: rgba(236, 240, 241, 1);
    height: 35vh;
    padding: 20px;
  }
  
  .industry-heading {
    font-family: Lato, sans-serif;
    font-size: medium;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: 2px;
    text-align: center;
    color: rgba(41, 128, 185, 1);
    border-bottom: 2.5px solid rgba(41, 128, 185, 1);
    margin-bottom: 20px;
  }
  
  .industry-description {
    font-family: Lato, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: rgba(44, 62, 80, 1);
  }

  .industry-description-heading{
    font-size: 10px;
    font-weight: 700;
    color: rgba(51, 51, 51, 1);
    line-height: 30px;

  }

  .industry-description-content{
    font-size: 12px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 15px;
  }
  
  .case-study-link {
    font-size: 10px;
    font-weight: 400;
    color: rgba(41, 128, 185, 1);
    line-height: 20px;
  }
  
  .case-study-link a {
    color: rgba(41, 128, 185, 1);
    text-decoration: none;
  }
  
  .case-study-link a:hover {
    text-decoration: underline;
  }
  
  /* src/styles/Careers.css */

.careers-container {
    width: 100%;
    /* height: 644px; */
    gap: 0px;
    background-color: rgba(236, 240, 241, 1);
    display: flex;
    flex-direction: column;
  }
  
  .upper-section {
    position: relative;
    margin-bottom: 80px;
    margin-top: 125px;
  }
  
  .line-image {
    width: 124px;
    height: 16px;
    position: absolute;
  }
  
  .testimonials-title {
    font-family: Merriweather, serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.04em;
    margin-left: 130px;
    color: rgba(41, 128, 185, 1);
    margin-top: -117px;
  }
  
  .testimonials-heading {
    font-family: Lato, sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: rgba(44, 62, 80, 1);
    margin: 5px;
    margin-left: 131px;
  }
  
  .lower-section {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  }
  
  .arrow {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 1;
    transition: opacity 0.3s;
  }
  
  .left-arrow {
    position: absolute;
    left: 10px;
  }
  
  .right-arrow {
    position: absolute;
    margin-left: 50%;
  }
  
  .cards-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    overflow: hidden;
    gap: 30px;
    margin-left: 200px;
  }
  
  .card {
    width: 200px;
    height: 250px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 0 10px;
  }
  
  .card-image {
    width: 115px;
    height: 115px;
    position: absolute;
    top: -57.5px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid white;
  }
  
  .card-image img {
    width: 100%;
    height: 100%;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .card-icon {
    width: 8px;
    text-align: center;
    height: 25px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
  }
  
  .card-icon img {
    width: 100%;
    height: 50%;
  }
  
  .card-name {
    font-family: 'Playfair Display', serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 10.2px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
  }
  
  .card-designation {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    color: rgba(128, 128, 128, 1);
    margin: 5px 0;
  }
  
  .card-stars {
    display: flex;
    gap: 2px;
  }
  
  .card-stars img {
    width: 16px;
    height: 16px;
  }
  
  .card-text {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    margin-top: 10px;
  }
  
  /* src/styles/MainComponent.css */

.main-container {
    position: relative;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 35px; */
    margin-bottom: 50px;
    /* border: 1px solid red; */
  }
  
  .background-overlay {
    position: absolute;
    width: 85%;
    height: 60%;
    border-radius: 10px;
    background-color: rgb(0, 0, 0);
    opacity: 0.9;
    margin-top: 10%;
  }
  
  .background-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/contactus-bg.png") no-repeat center center/cover;
    opacity: 0.2;
    border-radius: 10px;
  }
  
  .content {
    margin-top: 40px;
    position: relative;
    text-align: center;
    color: white;
  }
  
  .heading {
    font-size: 34px;
    width: 55%;
    margin: auto;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 5px;
  }
  
  .subheading {
    font-size: 24px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
  }
  
  .button-container-contactus {
    position: relative;
    display: inline-block;
  }
  
  .hover-bg-contactus {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    transform: rotate(-5deg);
    transition: opacity 0.3s ease-in-out;
    border-radius: 5px;
  }
  
  .get-in-touch-button {
    position: relative;
    z-index: 1;
    padding: 10px 20px;
    background-color: rgba(41, 128, 185, 1);
    z-index: 0;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .learn-more:hover + .hover-bg {
    opacity: 0;
  }
  
  .learn-more:hover {
    background-color: rgba(44, 62, 80, 1);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    /* opacity: 0;
      visibility: hidden; */
    transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
    z-index: 1000;
  }
  
  .overlay.visible {
    opacity: 1;
    visibility: visible;
  }
  
  .contact-us-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 550px;
    max-height: 500px;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .upper-section-form {
    width: 85%;
    margin: auto;
    border-bottom: 2px solid rgba(204, 204, 204, 0.6);
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .contact-us-form {
    margin-top: 20px;
    line-height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-us-container h1 {
    font-family: Lato, sans-serif;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: rgba(44, 62, 80, 1);
  }
  .contact-us-container p {
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: rgba(102, 102, 102, 1);
  }
  
  .Contactus-form-fullname {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .Contactus-form-fullname .name-left {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 14px;
  }
  
  .Contactus-form-fullname .name-right {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 14px;
    margin-left: 10px;
  }
  
  .Contactus-form-fullname .name-left input {
    height: 25px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  .Contactus-form-fullname .name-right input {
    height: 25px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  
  .Contactus-form-email-phonenumber {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .Contactus-form-email-phonenumber .email {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 50%;
  }
  .Contactus-form-email-phonenumber .email input {
    height: 25px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  
  .Contactus-form-email-phonenumber .number {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 14px;
    margin-left: 10px;
  }
  .Contactus-form-email-phonenumber .number input {
    height: 25px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  
  .message {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
  }
  
  .message textarea {
    height: 80px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  
  .checkbox-container-contactus {
    margin-top: 15px;
    margin-left: 5px;
    width: 100%;
    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    display: flex;
    color: rgba(102, 102, 102, 1);
  }
  
  .checkbox-container-contactus input {
    border: 1px solid rgba(204, 204, 204, 0.6);
  }
  .checkbox-container-contactus .receive-info {
    margin-left: 20px;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .checkbox-container-contactus .receive-info .receive-info-span{
    color: rgba(41, 128, 185, 1);
  }
  
  .submit-cancel-btn-contactus-form {
    display: flex;
    margin-top: 20px;
  }
  
  .submit-cancel-btn-contactus-form .cancel-btn-contactus-form {
    background-color: rgba(204, 204, 204, 1);
    color: rgba(44, 62, 80, 1);
    width: 140px;
    height: 34px;
    border-radius: 5px;
    border: none;
    font-weight: 600;
  }
  
  .submit-cancel-btn-contactus-form .submit-btn-contactus-form {
    background-color: rgba(44, 62, 80, 1);
    color: rgba(255, 255, 255, 1);
    margin-left: 15px;
    width: 140px;
    height: 34px;
    border-radius: 5px;
    border: none;
    font-weight: 600;
  }

  
  /* src/styles/Footer.css */

.footer-container {
    margin: 0 auto;
    background-color: rgba(236, 240, 241, 1);
  }
  
  .footer-upper-section {
    padding: 50px;
    display: flex;
    /* justify-content: center; */
  }
  .subscribe {
    width: 100%;
    color: rgba(44, 62, 80, 1);
    display: flex;
    margin-top: 40px;
  }
  .subscribe h2 {
    /* width: 60%; */
    font-family: Lato, sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 48px;
    color: rgba(44, 62, 80, 1);
    margin-bottom: 30px;
    margin-left: 80px;
  }
  .input-group {
    width: 26%;
    display: flex;
    height: 37px;
    text-align: center;
    margin: auto;
    margin-left: 25%;
  }
  .input-group input {
    width: 90%;
    padding: 8px;
    border: none;
    border-bottom: 1px solid rgba(189, 195, 199, 0.35);
    font-size: 14px;
    font-family: Lato, sans-serif;
    position: relative;
    border-radius: 5px;
    /* margin-left: 20px; */
  }
  .input-group button {
    position: absolute;
    background-color: rgba(44, 62, 80, 1);
    color: white;
    border: none;
    padding: 8px 30px;
    cursor: pointer;
    font-family: Lato, sans-serif;
    font-size: 14px;
    margin-left: 178px;
    border-radius: 5px;
    margin-top: 2px;
  }
  
  .comapnyinfo-socialicons{
    width: 40%;
  }
  
  .company-info {
    width: 100%;
  }
  
  .company-info .logo {
    display: flex;
    align-items: center;
  }
  
  .company-info .logo svg {
    width: 40px;
    height: 40px;
  }
  
  .company-info .logo span {
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: rgba(44, 62, 80, 1);
    margin-left: 10px;
  }
  
  .company-info p {
    margin-top: 25px;
    width: 75%;
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    color: rgba(102, 102, 102, 1);
  }
  
  .social-icons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .social-icons svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    fill: rgba(44, 62, 80, 1);
  }
  
  .footer-lower-section {
    padding: 45px;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
  }
  
  .quick-links,
  .services,
  .contact-details,
  .legal {
    width: 20%;
  }
  
  .quick-links h3,
  .services h3,
  .contact-details h3 {
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: rgba(44, 62, 80, 1);
    margin-bottom: 10px;
  }
  
  .links,
  .service-links,
  .info {
    display: flex;
    flex-direction: column;
  }
  
  .links div,
  .service-links div{
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
    margin-bottom: 8px;
  }
  
  .info-heading-footer{
    color: rgba(44, 62, 80, 1);
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 600;
    /* color: rgba(102, 102, 102, 1); */
    margin-bottom: 2px;
  }
  .info-heading-content{
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
    margin-bottom: 8px;
  }
  
  .legal {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    background-color: rgba(44, 62, 80, 1);
  }
  
  .legal .left-content {
    font-family: Lato, sans-serif;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .legal .right-content {
    font-family: Lato, sans-serif;
    font-size: 12px;
    color: 
    rgba(255, 255, 255, 0.8);
    cursor: pointer;
    /* text-decoration: underline; */
    margin-left: 10px;
  }
  
  .legal .right-content .right-content-left-span{
   
  }
  
  .legal .right-content .right-content-right-span{
  margin-left: 40px;
  }

  .resources-center{
    display: flex;
    flex-direction: column;
  }
  .line-left{
    border: 1px solid rgba(189, 195, 199, 0.6);
    /* transform: rotate(90deg); */
    margin-right: 20px;
    width: 110px;
    height: 0px;
    align-content: center;
    margin-top: 8px;
  }
  .trusted-partners{
    color: rgba(44, 62, 80, 1);
  }
  .line-right{
    border: 1px solid rgba(189, 195, 199, 0.6);
    /* transform: rotate(90deg); */
    margin-left: 20px;
    width: 110px;
    height: 0px;
    align-content: center;
    margin-top: 8px;
  }
  .resources-upper{
    margin: auto;
    display: flex;
  }
  .resources-lower{
    display: flex;
    margin: auto;
    margin-bottom: 30px;
  }

  .company-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .company-logo img{
    width: 50px;
    height: 50px;
  }  

  .company-name{
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
  }

  .line-image-home img{
    transform: rotate(270deg);
    width: 27px;
  }