.industries {
  font-family: sans-serif;
  color: rgba(255, 255, 255, 1);
  position: relative;
  /* color: black; */
  margin-top: -115px;
  margin-bottom: 89px;
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 700;
}

.industries-line-image {
    text-align: center;
    margin-top: -65px;
  }
  
  .industries-content-div {
    text-align: center;
  }
  
  .industries-title-second-heading {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
    text-align: center;
    color: rgba(41, 128, 185, 1);
  }
  
  .industries-heading-sub {
    font-size: 24px;
    width: 32vw;
    margin: auto;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
    color: rgba(44, 62, 80, 1);
  }

  .main-section-div-industries{
    margin-bottom: 40px;
  }
  .section-div-industries{
    display: flex;
    justify-content: center;
    /* padding: 20px; */
    margin-top: 30px;
  }
  .left-sec-div-industries{
    width: 25%;
    margin-right: 20vw;
  }
  .number-left-div-industries{
    font-weight: 800;
    color: rgb(236 240 241 / 70%);
    font-size: 130px;
    margin-left: 30px;
  }
  .box-left-div-industries{
    height: 50px;
    background-color: rgba(44, 62, 80, 1);
    color: rgba(255, 255, 255, 1);
    align-content: center;
    text-align: center;
    width: 150%;
    margin-top: -26px;
  }
  .right-part{
    text-align: left;
    margin-bottom: 10px;
    margin-left: 60px;
  }
  .left-part{
width: 25px;
height: 25px;
  }
  .left-part img{
    width: 25px;
    height: 25px;
    margin-top: 12px;
    margin-left: 20px;
  }
  .upper-right-div-industries{
    font-weight: 700;
    font-size: 16px;
  }
  .lower-right-div-industries{
    font-size: 10px;
    font-weight: 500;
  }
  .right-sec-div-industries{
    width: 80%;
  }
  .heading-right-sec-div-industries{
    font-size: 20px;
    color: rgba(44, 62, 80, 1);
    font-weight: 700;
    margin-left: 20px;
  }
  .card-right-sec-div-industries{
    display: flex;
  }
  .industries-featured-card{
    border: 1px solid rgba(44, 62, 80, 0.15);
    width: 215px;
    margin-left: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 15px;
}
.upper-sec-industries-featured-card img {
    width: 100%;
    height: 130px;
}
.lower-sec-industries-featured-card{
    padding: 10px;
}
.first-line-lower-sec-industries-featured-card{
    display: flex;
    text-align: center;
}
.profile-pic-first-line-lower-sec-industries-featured-card img{
    width: 35px;
    height: 35px;
    border-radius: 50px;
}
.userinfo-first-line-lower-sec-industries-featured-card{
    margin-left: 5px;
    margin-top: 10px;
    color: rgba(160, 171, 184, 1);
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.name-first-line-lower-sec-industries-featured-card-scroll{
  font-size: 16px;
}

.heading-first-line-lower-sec-industries-featured-card{
    color: rgba(44, 62, 80, 1);
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
}
.sub-heading-first-line-lower-sec-industries-featured-card{
    color: rgba(102, 102, 102, 1);
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
}
.date-first-line-lower-sec-industries-featured-card{
    color: rgba(160, 171, 184, 1);
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
}

/*  */
.left-section-div-industries{
    display: flex;
    justify-content: center;
    /* padding: 20px; */
    margin-top: 30px;
    margin-left: 45px;
}
.left-sec-div-industries-right{
    width: 25%;
    margin-right: 10vw;
}

/*  */
.box-left-div-industries-one{
    height: 50px;
    background-color: rgba(44, 62, 80, 1);
    color: rgba(255, 255, 255, 1);
    align-content: center;
    text-align: center;
    width: 150%;
    display: flex;
    margin-top: -26px;
  }
.right-part-one{
    text-align: left;
    margin-top: 10px;
    margin-left: 30px;
  }
  .left-part-one{
width: 25px;
height: 25px;
  }
  .left-part-one img{
    width: 25px;
    height: 25px;
    margin-top: 12px;
    margin-left: 20px;
  }
  .upper-right-div-industries-one{
    font-weight: 700;
    font-size: 16px;
  }
  .lower-right-div-industries-one{
    font-size: 10px;
    font-weight: 500;
  }
/*  */
.box-left-div-industries-two{
    height: 50px;
    background-color: rgba(44, 62, 80, 1);
    color: rgba(255, 255, 255, 1);
    align-content: center;
    text-align: center;
    width: 150%;
    margin-top: -26px;
    display: flex;
  }
.right-part-two{
    text-align: left;
    margin-top: 10px;
    margin-left: 30px;
  }
  .left-part-two{
width: 25px;
height: 25px;
  }
  .left-part-two img{
    width: 25px;
    height: 25px;
    margin-top: 12px;
    margin-left: 20px;
  }
  .upper-right-div-industries-two{
    font-weight: 700;
    font-size: 16px;
  }
  .lower-right-div-industries-two{
    font-size: 10px;
    font-weight: 500;
  }
/*  */
.box-left-div-industries-three{
    height: 50px;
    background-color: rgba(44, 62, 80, 1);
    color: rgba(255, 255, 255, 1);
    align-content: center;
    text-align: center;
    width: 150%;
    margin-top: -26px;
    display: flex;
  }
.right-part-three{
    text-align: left;
    margin-top: 10px;
    margin-left: 30px;
  }
  .left-part-three{
width: 25px;
height: 25px;
  }
  .left-part-three img{
    width: 25px;
    height: 25px;
    margin-top: 12px;
    margin-left: 20px;
  }
  .upper-right-div-industries-three{
    font-weight: 700;
    font-size: 16px;
  }
  .lower-right-div-industries-three{
    font-size: 10px;
    font-weight: 500;
  }
/*  */
.box-left-div-industries-four{
    height: 50px;
    background-color: rgba(44, 62, 80, 1);
    color: rgba(255, 255, 255, 1);
    align-content: center;
    text-align: center;
    width: 150%;
    margin-top: -26px;
    display: flex;
  }
.right-part-four{
    text-align: left;
    margin-top: 10px;
    margin-left: 30px;
  }
  .left-part-four{
width: 25px;
height: 25px;
  }
  .left-part-four img{
    width: 25px;
    height: 25px;
    margin-top: 12px;
    margin-left: 20px;
  }
  .upper-right-div-industries-four{
    font-weight: 700;
    font-size: 16px;
  }
  .lower-right-div-industries-four{
    font-size: 10px;
    font-weight: 500;
  }