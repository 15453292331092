.job-details {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-details h1 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.job-details h2 {
  font-size: 1.5em;
  margin-top: 20px;
  color: #555;
}

.job-overview,
.job-responsibilities,
.job-requirements,
.job-skills {
  margin-bottom: 20px;
}

.job-overview p,
.job-responsibilities ul,
.job-requirements ul,
.job-skills ul {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
}

.job-responsibilities ul,
.job-requirements ul,
.job-skills ul {
  list-style-type: disc;
  margin-left: 20px;
}

.job-responsibilities li,
.job-requirements li,
.job-skills li {
  margin-bottom: 10px;
}

.apply-section {
  text-align: center;
  margin-top: 30px;
}

.apply-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.apply-button:hover {
  background-color: #45a049;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-text {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-row label {
  width: 100%;
}

.form-row input[type="text"],
.form-row input[type="email"],
.form-row input[type="tel"],
.form-row input[type="url"],
.form-row textarea,
.form-row input[type="file"] {
  width: 48%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-row textarea {
  width: 100%;
  height: 100px;
}

.form-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cancel-button,
.apply-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.cancel-button:hover {
  background-color: #e53935;
}

.privacy-policy {
  color: blue;
  cursor: pointer;
}
